import { useState } from "react"
import {ToggleButton, ButtonGroup} from 'react-bootstrap'
import Cookies from 'js-cookie'
import { FcOk, FcCancel } from "react-icons/fc";
import './singleReportForm.css'



export default function SigleReportForm(){
    const [filename, setFilename] = useState("filename.csv")
    const [response, setResponse] = useState("")
    const [httpResponse, setHttpResponse] = useState(false)
    const [startSpinner, setStartSpinner] = useState(false)
    const [radioValue, setRadioValue] = useState('1');


    const radios = [
        { name: 'Ingestion Mode', value: '1' },
        { name: 'Trial Mode', value: '2' },
      ];




    const handleSubmit = (e)=>  {
        e.preventDefault()
        let cookieValue = Cookies.get('ReportHubAuth')
        fetch(`${process.env.REACT_APP_BASE_URL}/v1/report-automation/single?filename=${filename}&isTrialRun=${radioValue !== '1'}`, 
        {
            headers: {
            'Authorization': `Bearer ${cookieValue}`
          }, 
        method: 'POST'})
        .then(res => {
            if (res.ok) {
                return res.text()
            } else {
                if (res.status === 400){
                    throw new Error(`Status code: ${res.status}. Please double check the input values`)
                }if (res.status === 404){
                    throw new Error(`Status code: ${res.status}. Report file not found on GCS`)
                }if (res.status === 401){
                    throw new Error(`Status code: ${res.status}. Unauthorized`)
                } else {
                    throw new Error(`Status code: ${res.status}.`)
                }
            }
        })
        .then(data =>{
            console.log("Response is :")
            console.log(data)
            const resp = {isError: false, data: data}
            setStartSpinner(false)
            setHttpResponse(true)
            setResponse(resp)
        }).catch(err => {

            let data = {}
            data.errorMessage = err.toString() + " Please check filename"
            data.isError = true

            setStartSpinner(false)
            setHttpResponse(true)
            setResponse(data)
        })
        setStartSpinner(true)
    }

    return(
        <div className={radioValue === '1'? "" : "alert"}>
            <p></p>
            <form className="row g-3 needs-validation" noValidate>
                <div className="col-md-3">
                    <label className="form-label">Filename:</label>
                    <input width="200" value={filename} onInput={e => setFilename(e.target.value)}/>

                </div>
                <div className="col-md-3">
                <ButtonGroup>
                    {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={idx % 2 ? 'outline-danger' : 'outline-success'}
                        name="radio"
                        value={radio.value}
                        checked={radioValue === radio.value}
                        onChange={(e) => setRadioValue(e.currentTarget.value)}
                    >
                        {radio.name}
                    </ToggleButton>
                    ))}
                </ButtonGroup>
                </div>

                
                <div className="col-12">
                    <button className="btn btn-primary" onClick={handleSubmit} type="submit">{radioValue === '1' ? "Ingest report" : "Ingest Report in Trial Mode"}</button>
                </div>
            </form>
            <p></p>
            {startSpinner && <div className="spinner-border text-primary" role="status">
        <span className="sr-only"></span>
      </div>}
      {
          httpResponse && <div>
                {response.isError && <h4> <FcCancel></FcCancel> <strong>Server responded with error: </strong> {response.errorMessage}  </h4>}
                {!response.isError && <h4> <FcOk></FcOk> <t /> <strong>Ingestion and Process Identifier: </strong>{response.data}  </h4>}

          </div>
      }
        </div> 
      )
}

